


















































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'PasswordStep',
  components: {
    BaseInput: () => import('./BaseInput.vue'),
  },
})
export default class PasswordStep extends Vue {
  @Prop({ type: String, required: true }) readonly pass!: string
  @Prop({ type: String, required: true }) readonly repeatPass!: string
  @Prop({ type: String, required: true }) readonly hint!: string
  @Prop({ type: String, required: true }) readonly passLabel!: string
  @Prop({ type: Boolean, required: true }) readonly passErr!: boolean

  get label (): string {
    return this.passLabel
      ? this.passLabel
      : this.$t('auth.twoFactorAuth.passDefaultLabel').toString()
  }

  showPass = false
}
